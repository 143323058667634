import React from 'react';
import { Redirect } from 'react-router-dom';
import { PrivateRoute } from "@tmatt-tech/react_common";
import { OVERVIEW_DASHBOARD_PATH } from "routes/dashboard/constants";
import { dashboardRoutes, hiddenDashboardRoutes } from "routes/dashboard/routes";
import { authRoutes } from "routes/auth/routes";
import { hiddenPackBatchUploadRoutes, packBatchUploadRoutes } from "routes/packBatchUpload/routes";
import { hiddenOrderPackRoutes, orderPackRoutes } from "routes/orderPack/routes";
import { hiddenPackRoutes, packRoutes } from "routes/pack/routes";
import { clientRoutes, hiddenClientRoutes } from "routes/client/routes";
import {
  exportInternationalBatchUploadRoutes,
  hiddenExportInternationalBatchUploadRoutes
} from "routes/exportInternationalBatchUpload/routes";
import {
  exportDimensionBatchUploadRoutes,
  hiddenExportDimensionBatchUploadRoutes
} from "routes/exportDimensionBatchUpload/routes";
import { hiddenTransactionRoutes, transactionRoutes } from "routes/transaction/routes";
import { hiddenReceivedNoticeRoutes } from "routes/receivedNotice/routes";
import { couponRuleRoutes, hiddenCouponRuleRoutes } from "routes/couponRule/routes";
import { hiddenWechatNoticeRoutes, wechatNoticeRoutes } from "routes/wechatNotice/routes";
import { hiddenOrderSeaFreightRoutes, orderSeaFreightRoutes } from "routes/orderSeaFreight/routes";
import { hiddenSeaFreightContainerRoutes, seaFreightContainerRoutes } from "routes/seaFreightContainer/routes";
import { hiddenOrderDeclarationRoutes } from "routes/orderDeclaration/routes";

// root routes
const rootRoute = {
  path: '/',
  exact: true,
  component: () => <Redirect to={OVERVIEW_DASHBOARD_PATH} />,
  route: PrivateRoute,
};

// flatten the list of all nested routes
const flattenRoutes = routes => {
  let flatRoutes = [];

  routes = routes || [];
  routes.forEach(item => {
    flatRoutes.push(item);

    if (typeof item.children !== 'undefined') {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

const hiddenRoutes = {
  children: [
    ...hiddenDashboardRoutes.children,
    ...hiddenPackBatchUploadRoutes.children,
    ...hiddenOrderPackRoutes.children,
    ...hiddenPackRoutes.children,
    ...hiddenClientRoutes.children,
    ...hiddenExportInternationalBatchUploadRoutes.children,
    ...hiddenExportDimensionBatchUploadRoutes.children,
    ...hiddenTransactionRoutes.children,
    ...hiddenReceivedNoticeRoutes.children,
    ...hiddenCouponRuleRoutes.children,
    ...hiddenWechatNoticeRoutes.children,
    ...hiddenOrderSeaFreightRoutes.children,
    ...hiddenSeaFreightContainerRoutes.children,
    ...hiddenOrderDeclarationRoutes.children,
  ],
};

// All routes
const allRoutes = [
  rootRoute,
  authRoutes,
  dashboardRoutes,
  clientRoutes,
  packRoutes,
  orderPackRoutes,
  orderSeaFreightRoutes,
  seaFreightContainerRoutes,
  packBatchUploadRoutes,
  exportInternationalBatchUploadRoutes,
  exportDimensionBatchUploadRoutes,
  transactionRoutes,
  couponRuleRoutes,
  wechatNoticeRoutes,
  hiddenRoutes,
];

const authProtectedRoutes = [
  rootRoute,
  dashboardRoutes,
  orderPackRoutes,
  orderSeaFreightRoutes,
  seaFreightContainerRoutes,
  clientRoutes,
  transactionRoutes,
  couponRuleRoutes,
  packBatchUploadRoutes,
  exportInternationalBatchUploadRoutes,
  exportDimensionBatchUploadRoutes,
  packRoutes,
  wechatNoticeRoutes,
];

const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes };
